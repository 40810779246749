import store from '@/store'
import router from '@/router'
import api from '../api'
import Swal from 'sweetalert2';


const auth = {
    namespaced:true,
    store,
    state(){
        return{
            // Use localStorage to get the initial value of isloggedIn
            isloggedIn: sessionStorage.getItem('isAuthenticated') === 'true',
        }
    },
    mutations:{
        login(state, payload) {
            sessionStorage.setItem('authToken', payload.token);
            sessionStorage.setItem('user', payload.user.name);
            sessionStorage.setItem('isAuthenticated', 'true');
            
            // Update the state and store it in localStorage
            state.isloggedIn = true;
            sessionStorage.setItem('isAuthenticated', 'true');

            console.log(payload);
            router.push('/admin');
        },
        logout(state, payload) {
            console.log(payload);
            sessionStorage.removeItem('authToken');
            sessionStorage.removeItem('isAuthenticated');
            sessionStorage.removeItem('user');
            
            // Update the state and localStorage
            state.isloggedIn = false;
            sessionStorage.removeItem('isAuthenticated');
            router.push('/auth');
        },
    },
    actions:{
        async login(context,payload){
            await api.post(`/api/v1/login`,{
                "email" : payload.payload.email,
                "password": payload.payload.password
            }).then((res) => {
                context.commit('login', res.data)
                router.push('/')

                Swal.fire({
                    toast: true,
                    position: 'top-end',
                    icon: 'success',
                    title: 'Logged In',
                    showConfirmButton: false,
                    timer: 1000
                });
            
            })
            .catch(error => {
                console.log(error)
                
                Swal.fire({
                    toast: true,
                    position: 'top-end',
                    icon: 'error',
                    text:"The password you’ve entered is incorrect. ",
                    showConfirmButton: false,
                    timer: 1000
                })
            });
            // console.log(api.post(`/api/v1/login`))
        },
        async logout(context,payload){
            await api.post(`/api/v1/logout`,{
            }).then((res) => {
                context.commit('logout',res.data)
            }).catch((err) => {
                console.log(err)
            })
        }
    },
    getters:{
        isloggedIn(state){
            return state.isloggedIn
        }
    }
}

export default auth