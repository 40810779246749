<template>
  <v-container class="mb-16">
      <v-row>
        <v-col cols="3" lg="3" md="3" sm="12" xs="12">
          <v-btn @click="addCareer" height="70px" outlined color="primary" block >
            ADD<v-icon> mdi-plus </v-icon>
          </v-btn>
        </v-col>
        <v-col cols="">
          <v-card class="pa-1" >
            <v-row>
              <v-col>
                <v-card-title> Careers </v-card-title>
              </v-col>
              <v-col>
               
              </v-col>
            </v-row>
            
          </v-card>
        </v-col>
      </v-row>

      <v-card class="mt-5">
        <custom-table
          :headers="headers"
          :datas="datas"
          :buttons="buttons"
          searchLabel="Search"
        />
      </v-card>

      <v-dialog v-model="careerDialog" width="800" persistent>
         <add-career-dialog></add-career-dialog>
      </v-dialog>

      <!-- v-if hack to force render a component -->
      <v-dialog v-model="modifyDialog" v-if="modifyDialog" width="800" persistent>
        <modify-career v-if="modifyDialog" ></modify-career>
      </v-dialog>
  </v-container>
</template>

<script>
import customTable from '@/components/customTable.vue'
import addCareerDialog from '@/components/addCareerDialog.vue';
import modifyCareer from '@/components/modifyCareer.vue'
import { mapGetters } from 'vuex';
  export default {
    components:{
      customTable,
      addCareerDialog,
      modifyCareer
    },
    mounted(){
      this.$store.dispatch({
        type:"admin/companies"
      })

      this.$store.dispatch({
        type:"admin/statuses"
      })
    },
    data(){
      return{
         headers: [
          {
            text: 'Position Title',
            align: 'start',
            sortable: true,
            value: 'positionTitle',
          },
          {
            text: 'Company',
            align: 'start',
            sortable: true,
            value: 'company',
          },
          {
            text: 'Project Designation',
            align: 'start',
            sortable: true,
            value: 'workLocation',
          },
          {
            text: 'Status',
            align: 'start',
            sortable: true,
            value: 'status',
            test1: (item) => {
              this.status(item);
            },
          },
          {
            text: 'Hirig Duration',
            align: 'start',
            sortable: true,
            value: 'hiringDuration',
          },
          {
            text: 'Date Created',
            align: 'start',
            sortable: true,
            value: 'created_at',
          },
          {
            text: "Actions",
            value: "actions",
            sortable: false,
          },
        ],
        buttons: [
          {
            icon: "mdi-file-edit-outline",
            color: "primary",
            tooltip: "Modify",
            position: "left",
            action: (item) => {
              this.triggerModifyDialog(item);
            },
          },
          {
            icon: "mdi-trash-can-outline",
            color: "error",
            tooltip: "Delete Account",
            position: "left",
            action: (item) => {
              this.triggerDeleteDialog(item);
            },
          },
        ],
      }
    },
    computed:{
      ...mapGetters({
        datas:"home/hiringData",
        careerDialog: "admin/careerDialog",
        modifyDialog: "admin/modifyDialog"
      })
    }
    ,methods:{
      addCareer(){
        // this.careerDialog = true
        this.$store.dispatch({
          type:"admin/careerDialog",
          payload: true
        })
      },
      triggerModifyDialog(item){
        this.$store.dispatch({
          type:"admin/modifyDialog",
          payload: true
        }).then(() => {
          this.$store.dispatch({
            type:"admin/selectedModify",
            payload: item
          })
        })
      },
      triggerDeleteDialog(item){
        this.$swal.fire({
          title: 'Are you sure?',
          text: "You won't be able to revert this!",
          icon: 'warning',
          showCancelButton: true,
          confirmButtonColor: '#3085d6',
          cancelButtonColor: '#d33',
          confirmButtonText: 'Yes, delete it!'
        }).then((result) => {
          if (result.isConfirmed){
            this.$store.dispatch({
              type:"home/delete",
              payload:{
                isConfirmed : result.isConfirmed,
                id : item.id,
                index : this.datas.indexOf(item)
              }
            })
          }
          
        })
      }
    }
  }
</script>
