<template>
   <v-card>
        <v-card-title>
            <v-row class="-mb-10">
                <v-col>
                    Create 
                </v-col>
                <v-col cols="3">
                    <v-select
                        :items="statuses"
                        dense
                        outlined
                        item-text="status"
                        item-value="id"
                        return-object
                        v-model="status"
                    ></v-select>
                </v-col>
            </v-row>
        </v-card-title>
        <v-divider></v-divider>
        <v-form @submit.prevent="save">
            <v-card-text class="-space-y-6">
                <v-select
                    label="Company"
                    :items="companies"
                    dense
                    outlined
                    item-text="name"
                    item-value="id"
                    v-model="company"
                    return-object
                ></v-select>
                <v-row>
                    <v-col>
                        <v-text-field
                            label="Work Location"
                            outlined
                            dense
                            v-model="workLocation"
                        ></v-text-field>
                    </v-col>
                    <v-col>
                        <v-text-field
                            label="Hiring Duration"
                            outlined
                            dense
                            v-model="hiringDuration"
                        ></v-text-field>
                    </v-col>
                </v-row>
                <v-row>
                    <v-col>
                        <v-card-title>Job Information</v-card-title>
                    </v-col>
                    <v-col cols="8" class="pt-11">
                        <v-divider></v-divider>
                    </v-col>
                </v-row>
                <v-card-title></v-card-title>
                <v-row>
                    <v-col>
                        <v-text-field
                            label="Position Title"
                            outlined
                            dense
                            v-model="positionTitle"
                        ></v-text-field>
                    </v-col>
                    <v-col cols="3">
                        <v-text-field
                            label="How Many?"
                            outlined
                            dense
                            type="number"
                            v-model="counts"
                        ></v-text-field>
                    </v-col>
                </v-row>

                <v-textarea
                    label="Job Description"
                    value=""
                    class="mt-2"
                    rows="3"
                    row-height="25"
                    shaped
                    outlined
                    v-model="jobDescription"
                ></v-textarea>

                <v-textarea
                    label="Qualifications"
                    value=""
                    class="mt-2"
                    rows="3"
                    row-height="25"
                    shaped
                    outlined
                    v-model="qualifications"
                ></v-textarea>
            </v-card-text>
            <v-divider></v-divider>
            <v-card-actions>
                <v-spacer></v-spacer>
                <v-btn text outlined
                    color="primary"
                    type="submit"
                >
                    create
                </v-btn>
                <v-btn text outlined
                    color="error"
                    @click="cancel"
                >
                    Cancel
                </v-btn>
            </v-card-actions>
        </v-form>
   </v-card>
</template>

<script>
import { onActivated } from 'vue'
import { mapGetters } from 'vuex'
export default {
    data(){
        return{
            company:"",
            positionTitle:"",
            counts:1,
            workLocation:"",
            hiringDuration:"--/--/---- - --/--/----",
            jobDescription:[],
            qualifications:[],
            status: 1,
            dateCreated:""
            // dateCreated:"08-22-2023",
        }
    },
    methods:{
        save(){
            this.$store.dispatch({
                type:"home/add",
                payload:{
                   "company": this.company.id == undefined ? 1 : this.company.id,
                   "positionTitle": this.positionTitle,
                   "counts": this.counts,
                   "workLocation": this.workLocation,
                   "hiringDuration": this.hiringDuration,
                   "jobDescription": this.jobDescription,
                   "qualifications": this.qualifications,
                   "status": this.status.id == undefined ? 1 : this.status.id,
                   "dateCreated": this.dateCreated
                }
            }).then(() => {
                this.$store.dispatch({
                    type:"admin/careerDialog",
                    payload: false
                }).then(() => {
                    this.$swal.fire({
                        toast: true,
                        position: 'top-end',
                        icon: 'success',
                        title: 'Created',
                        showConfirmButton: false,
                        timer: 1500
                    })
                })

                this.company = ""
                this.positionTitle = ""
                this.counts = 1
                this.workLocation = ""
                this.hiringDuration = "--/--/---- - --/--/----"
                this.jobDescription = ""
                this.qualifications = ""
                this.status = 1
                this.dateCreated = ""
                
            }).catch(() => {
                this.$swal.fire({
                    toast: true,
                    position: 'top-end',
                    icon: 'error',
                    title: 'Failed to create',
                    showConfirmButton: false,
                    timer: 1500
                })
            })
        },
        cancel(){
            this.$store.dispatch({
                type:"admin/careerDialog",
                payload: false
            })
        }
    },
    computed:{
        ...mapGetters({
            companies:"admin/companies",
            statuses: "admin/statuses"
        }),
    }
}
</script>