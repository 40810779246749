import store from '@/store'
import api from '../api'
import Swal from 'sweetalert2';
const admin = {
    namespaced:true,
    store,
    state(){
        return{
            selectedData:[],
            companies:['Cresta De Oro Philippines Inc.',"Goldridge Const. & Dev't. Corp."],
            statuses:[],
            careerDialog: false,
            modifyDialog: false,
        }
    },
    mutations:{
        selected(state,payload){
            state.selectedData = payload
        }, 
        careerDialog(state,payload){
            state.careerDialog = payload
        },
        modifyDialog(state,payload){
            state.modifyDialog = payload
        },
        selectedModify(state,payload){
            state.selectedData = payload
        },
        companies(state,payload){
            state.companies = payload
        },
        statuses(state,payload){
            state.statuses = payload
        }
    },
    actions:{
        selected(context,payload){
            context.commit('selected', payload.payload.data)
        },
        careerDialog(context,payload){
            context.commit('careerDialog', payload.payload)
        },
        modifyDialog(context,payload){
            context.commit('modifyDialog', payload.payload)
        },
        selectedModify(context,payload){
            context.commit('selectedModify', payload.payload)
        },
        async companies(context,payload){
            await api.get(`/api/v1/companies`)
            .then((res) => {
                context.commit("companies",res.data.data);
            }).catch((err) => {
                console.log(err)
            })
        },
        async statuses(context,payload){
            await api.get(`/api/v1/statuses`)
            .then((res) => {
                context.commit('statuses', res.data.data)
            }).catch((err) => {{
                console.log(err)
            }})
        },
    },
    getters:{
       companies(state){
        return state.companies
       },
       careerDialog(state){
        return state.careerDialog
       },
       selected(state){
        return state.selectedData
       },
       modifyDialog(state){
        return state.modifyDialog
       },
       statuses(state){
        return state.statuses
       }
    }
}

export default admin