<template>
    <v-card  width="800">
        <v-card-title class="">
            <v-row class="-mb-10">
                    <v-col>
                        Modify
                    </v-col>
                    <v-col cols="3">
                        <v-select
                            :items="statuses"
                            dense
                            outlined
                            item-text="status"
                            item-value="id"
                            return-object
                            v-model="status"
                        ></v-select>
                    </v-col>
                </v-row>
        </v-card-title>
        <v-divider></v-divider>
        <v-card-text class="mt-2">
            <v-form @submit.prevent="modify">
                <v-card-text class="-space-y-6">
                    <v-select
                        label="Company"
                        :items="companies"
                        dense
                        outlined
                        v-model="company"
                        item-text="name"
                        item-value="id"
                        return-object
                    ></v-select>
                    <v-row>
                        <v-col>
                            <v-text-field
                                label="Work Location"
                                outlined
                                dense
                                v-model="workLocation"
                                :value="selected.workLocation"
                            ></v-text-field>
                        </v-col>
                        <v-col>
                            <v-text-field
                                label="Hiring Duration"
                                outlined
                                dense
                                v-model="hiringDuration"
                            ></v-text-field>
                        </v-col>
                    </v-row>
                    <v-row>
                        <v-col>
                            <v-card-title>Job Information</v-card-title>
                        </v-col>
                        <v-col cols="8" class="pt-11">
                            <v-divider></v-divider>
                        </v-col>
                    </v-row>
                    <v-card-title></v-card-title>
                    <v-row>
                        <v-col>
                            <v-text-field
                                label="Position Title"
                                outlined
                                dense
                                v-model="positionTitle"
                            ></v-text-field>
                        </v-col>
                        <v-col cols="3">
                            <v-text-field
                                label="How Many?"
                                outlined
                                dense
                                type="number"
                                v-model="counts"
                            ></v-text-field>
                        </v-col>
                    </v-row>

                    <v-textarea
                        label="Job Description"
                        value="sdfasdsdf"
                        class="mt-2"
                        rows="3"
                        row-height="25"
                        shaped
                        v-model="jobDescription"
                    ></v-textarea>

                    <v-textarea
                        label="Qualifications"
                        value=""
                        class="mt-2"
                        rows="3"
                        row-height="25"
                        shaped
                        v-model="qualifications"
                    ></v-textarea>
                    
                </v-card-text>
                <v-divider></v-divider>
                <v-card-actions>
                    <v-spacer></v-spacer>
                    <v-btn
                        color="success"
                        outlined
                        type="submit"
                    >
                        Modify
                    </v-btn>
                    <v-btn
                        color="error"
                        outlined
                        @click="cancel"
                    >
                        Cancel
                    </v-btn>
                </v-card-actions>
            </v-form>
        </v-card-text>
        
    </v-card>
</template>
<script>
import { mapGetters } from 'vuex'
export default {
    mounted(){
        setTimeout(() => {
            this.company = this.selected.company
            this.workLocation = this.selected.workLocation
            this.status = this.selected.status.id
            this.hiringDuration = this.selected.hiringDuration
            this.positionTitle = this.selected.positionTitle
            this.counts = this.selected.counts,
            this.jobDescription = this.selected.jobDescription
            this.qualifications = this.selected.qualifications
        }, 100);
    },
    data(){
        return{
            status: "",
            company: "",
            workLocation: "",
            hiringDuration : "",
            positionTitle : "",
            counts: "",
            jobDescription: "",
            qualifications: ""
        }
    },
    methods:{
        modify(){
           this.$store.dispatch({
            type:"home/modify",
            payload:{
                "id": this.selected.id,
                "company": this.company.id,
                "positionTitle": this.positionTitle,
                "counts": this.counts,
                "workLocation": this.workLocation,
                "hiringDuration": this.hiringDuration,
                "jobDescription": this.jobDescription,
                "qualifications": this.qualifications,
                "status": this.status.id == undefined ? 1 : this.status.id,
            }
           }).then(() => {
                this.$store.dispatch({
                    type:"home/hiring"
                })
           })
        },
        cancel(){
            this.$store.dispatch({
                type:"admin/modifyDialog",
                payload : false
            })
        },
    },
    computed:{
        ...mapGetters({
            companies : "admin/companies",
            selected : "admin/selected",
            modifyDialog: "admin/modifyDialog",
            statuses: "admin/statuses",
        }),
    },
}
</script>