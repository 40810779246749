import store from '@/store'
import router from '@/router'
import api from '../api'
import Swal from 'sweetalert2'

const home = {
    namespaced:true,
    store,
    state(){
        return{
            data: []
        }
    },
    mutations:{
      add(state,payload){
        state.data.push(payload)
      },
      hiring(state,payload){
        console.log(payload)
        state.data = payload.data
      },
      delete(state,payload){
        state.data.splice(payload.index, 1);
      },
      modify(state,payload){
        const updatedItem = payload; // Assuming the API response contains the updated item
        const index = state.data.findIndex(item => item.id === updatedItem.id);

        if (index !== -1) {
          state.data[index] = updatedItem;
        }
      }
    },
    actions:{
      async add(context,payload){
        await api.post(`/api/v1/hiring`,{
          company : payload.payload.company,
          positionTitle : payload.payload.positionTitle,
          counts: payload.payload.counts,
          workLocation : payload.payload.workLocation,
          jobDescription : payload.payload.jobDescription,
          hiringDuration : payload.payload.hiringDuration,
          qualifications : payload.payload.qualifications == "" ? "N/A" : payload.payload.qualifications,
          dateCreated : payload.payload.dateCreated,
          status : payload.payload.status
        }).then((res) => {
          console.log(res)
          context.commit('add', res.data)
        })
      },
      async hiring(context,payload){
        await api.get(`/api/v1/hiring`,{})
        .then((res) => {
          context.commit('hiring', res.data)
        }).catch((err) => {
          console.log(err)
        })
      },
      async delete(context,payload){
        context.commit('delete', payload.payload)
        await api.delete(`/api/v1/hiring/${payload.payload.id}`)
        .then((res) => {
            console.log(res)

             if (payload.payload.isConfirmed) {
                Swal.fire(
                'Deleted!',
                'Your file has been deleted.',
                'success'
                )
            }
        })
      },
      async modify(context, payload) {
        try {
          const res = await api.put(`/api/v1/hiring/${payload.payload.id}`, {
            company: payload.payload.company,
            positionTitle: payload.payload.positionTitle,
            counts: payload.payload.counts,
            workLocation: payload.payload.workLocation,
            jobDescription: payload.payload.jobDescription,
            hiringDuration: payload.payload.hiringDuration,
            qualifications: payload.payload.qualifications == "" ? "N/A" : payload.payload.qualifications,
            status: payload.payload.status,
          });
      
          // Update the data in the store
          context.commit("modify", res.data);
          
          context.dispatch("admin/modifyDialog", false, { root: true });
          // Show a success message
          Swal.fire({
            toast: true,
            position: 'top-end',
            icon: 'success',
            title: 'Modified',
            showConfirmButton: false,
            timer: 1500,
          }).then(() => {
      
          });
        } catch (err) {
          console.log(err);
        }
      }
      
    },
    getters:{
        hiringData(state){
            return state.data
        }
    }
}

export default home