<template>
  <v-card flat>
    <v-card-title class="p-0 m-0">
      <v-row>
        <v-col cols="3">
          <v-text-field
            v-model="search"
            clearable
            :label="searchLabel"
            color="blue-accent-3"
            append-inner-icon="mdi-magnify"
            dense
            outlined
            class="mt-6 ml-4"
          >
            <template v-slot:append-inner>
              <v-fade-transition leave-absolute>
                <v-progress-circular
                  v-if="loading"
                  color="info"
                  indeterminate
                  size="24"
                ></v-progress-circular>

                <img
                  v-else
                  height="24"
                  width="24"
                  src="https://cdn.vuetifyjs.com/images/logos/v-alt.svg"
                  alt=""
                />
              </v-fade-transition>
            </template>

            <template v-slot:append>
              <v-menu>
                <v-card>
                  <v-card-text class="pa-6"> </v-card-text>
                </v-card>
              </v-menu>
            </template>
          </v-text-field>
        </v-col>
        <v-spacer></v-spacer>
      </v-row>
    </v-card-title>
    <v-divider></v-divider>
    <v-data-table
      :loading="!isLoading"
      loading-text="Loading... Please wait"
      :headers="headers"
      :items="datas"
      :search="search"
      :buttons="buttons"
    >
      <!-- action global -->

      <template v-slot:[`item.actions`]="{ item }">
        <v-tooltip bottom v-for="(button, index) in buttons" :key="index">
          <template v-slot:activator="{ attrs }">
            <v-icon
              small
              class="mr-2"
              v-on:click="button.action(item,index)"
              :color="button.color"
              v-bind="attrs"
              button.position
            >
              {{ button.icon }}
            </v-icon>
          </template>
          <span>sadasdasda </span>
        </v-tooltip>
      </template>
    <!-- action global ends here -->

    <!-- activate deactivate button starts here -->

    <!-- activate deacticate buttong ends here -->

    <!-- Status Data Starts here -->
      <template v-slot:[`item.status`] = {item}>
        <v-chip
          class="centered"
          :color="item.status.value ? 'success' : 'error'" outlined small>
          {{item.status.value ? 'Activate' : 'Deactivated'}}
        </v-chip>
      </template>
    <!-- Status Data Ends' here -->

    <!-- Position Title Data Starts here -->
    <template v-slot:[`item.positionTitle`] = {item}>
        <v-btn
          text
          color="primary"
          link
          @click="selected(item)"
        >
          {{ item.positionTitle }} ({{item.counts}})
        </v-btn>
      </template>
    <!-- Position Title Data Ends' here -->

    <!-- Company Data Starts here -->
    <template v-slot:[`item.company`] = "{ item }">
      {{ item.company ? item.company.name : 'N/A' }}
    </template>
    <!-- Company Data Ends' here -->

    <!-- Created at Data Starts here -->
    <template v-slot:[`item.created_at`] = "{ item }">
      {{ formatDate(item.created_at) }}
    </template>
    <!-- Created at Data Ends here -->
    </v-data-table>
  </v-card>
</template>
<script>
export default {
  props: [
    "headers",
    "datas",
    "searchLabel",
    "buttons",
    "filterItems",
    "defaultFilter",
  ],
  name: "",
  mounted() {
    // console.log(this.headers.title);
  },
  data() {
    return {
      search: "",
      isLoading: true,
      dialog: false,
      dialogDelete: false,
      editedIndex: -1,
      selectedItem: [],
      filter: this.defaultFilter,
      loading: false,
    };
  },
  methods: {
    selected(data){
      this.$store.dispatch({
        type:"admin/selected",
        payload:{
          data
        }
      }).then(() => {
        this.$router.push('/view')
      })
    },
    formatDate(isoDate) {
      const date = new Date(isoDate);
      // Format the date as needed
      const options = { year: 'numeric', month: 'long', day: 'numeric', hour: '2-digit', minute: '2-digit' };
      return date.toLocaleDateString('en-US', options); // Adjust the locale as needed
    },
  },
};
</script>
