<template>
  <v-app >
    <v-app-bar
      app
      color="blue-grey darken-4"
      dark
    >
      <div class="d-flex ">
        <v-img
          class="shrinktext-sm hidden-md-and-down"
          contain
          src="@/assets/logo.png"
          transition="scale-transition"
          width="200"
        />
        <v-img
          class="shrinktext-sm hidden-lg-and-up"
          contain
          src="@/assets/gcdc_logo.png"
          transition="scale-transition"
          width="50"
        />
        <v-card-title transition="fade-transition">
          CAREERS
        </v-card-title>
      </div>

      <v-spacer></v-spacer>

      <v-btn
        href="https://www.goldridgecdc.com/"
        text
        dense
        small
        v-if="!isloggedIn"
      >
        <span class="mr-1">Official Website</span>
        <v-icon>mdi-open-in-new</v-icon>
      </v-btn>
      
      <div v-if="isloggedIn">
        <v-btn class="mr-3" outlined
          v-if="$router.history.current.path == '/admin'"
          @click="viewMode"
        >View Mode</v-btn>
        <v-btn class="mr-3" outlined
        @click="adminMode"
          v-if="$router.history.current.path != '/admin'">Admin View
        </v-btn>
        <v-menu offset-y class="" >
          <template v-slot:activator="{ on, attrs }">
              
              <v-avatar
                color="primary"
                size="46"
                v-bind="attrs"
                v-on="on"
                class="mr-2 ml-4"
              >{{avatar}}</v-avatar>
              <v-divider vertical></v-divider>
          </template>
          <v-card>
            <v-row>
              <v-col cols="3">
                <v-avatar
                    color="primary"
                    size="46"
                    class="ml-2 mt-2 text-white"
                    dark
                  >{{avatar}}
                </v-avatar>
              </v-col>
              <v-col class="-mt-2 -ml-1">
                <v-card-title class="text-base -mb-5;">
                  {{name}}
                </v-card-title>
                <v-card-subtitle class="-ml-0">
                  Administrator 
                </v-card-subtitle>
              </v-col>
            </v-row>
            <v-divider></v-divider>
            <v-list class="-pt-10">
                <v-list-item link color="primary" @click="logout">
                  <v-list-item-title  class="-pt-10">
                    <v-icon  class="-mt-1 mr-1" dense>
                        mdi-file-edit-outline
                    </v-icon> 
                      Logout
                  </v-list-item-title>
                </v-list-item>
              </v-list>
          </v-card>
        </v-menu>
      </div>
        


    </v-app-bar>

    <v-main class="bg-slate-100">
      <v-container >
        <router-view/>
      </v-container>
    </v-main>

    <v-footer dark>
        <div class="text-xs font-normal ">Copyright © Goldridge Const. & Dev't Corp. • <span class="ml-1">Cresta De Oro Philippines Inc.</span> • <span class="ml-1">La Jolla</span>    2023</div>
    </v-footer>
  </v-app>

</template>

<script>
import { mapGetters } from 'vuex';

export default {
  name: 'App',
  data: () => ({
    items: [
      { title: 'Click Me' },
      { title: 'Click Me' },
      { title: 'Click Me' },
      { title: 'Click Me' },
    ],
  }),
  mounted(){
    this.$store.dispatch({
      type:"home/hiring"
    })
  },
  methods: {
    logout() {
      this.$store.dispatch({
        type: 'auth/logout',
      });
    },
    adminMode() {
      this.$router.push('/admin');
    },
    viewMode() {
      this.$router.push('/');
    },
  },
  computed: {
    ...mapGetters({
      isloggedIn: 'auth/isloggedIn',
    }),
    name() {
      return sessionStorage.getItem('user');
    },
    avatar() {
      const words = sessionStorage.getItem('user').split(' ');

      let initials = '';

      for (const word of words) {
        if (word.length > 0) {
          initials += word[0].toUpperCase(); // Convert to uppercase if needed
        }
      }

      return initials;
    },
  },
}
</script>