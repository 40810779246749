import Vue from 'vue'
import VueRouter from 'vue-router'
import Admin from '../views/Admin.vue'

Vue.use(VueRouter)

const routes = [
  {
    path: '/admin',
    name: 'Admin',
    component: Admin,
    meta: { requiresAuth: true }
  },
  {
    path: '/',
    name: 'home',
    component: () => import('../views/home.vue'),
  },
  {
    path: '/auth',
    name: 'login',
    component: () => import('../views/login.vue'),
  },
  {
    path: '/view',
    name: 'view',
    component: () => import('../views/view.vue'),
    meta: { requiresAuth: true }
  },
]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes
})

router.beforeEach((to, from, next) => {
  // Check if the route requires authentication
  if (to.matched.some((route) => route.meta.requiresAuth)) {
    
    // Check if the user is authenticated (customize this based on your authentication mechanism)
    if (sessionStorage.getItem('isAuthenticated') !== 'true') {
      // If not authenticated, redirect to the login page
      next('/auth');
    } else {
      // If authenticated, continue with the navigation
      next();
    }
  } else {
    // If the route does not require authentication, continue with the navigation
    next();
  }
});


export default router
